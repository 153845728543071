import React from 'react';
import {
  Center,
  Container,
  Flex,
  Divider,
  SimpleGrid,
  Image,
  VStack,
  Text,
  HStack,
  Input,
  Heading,
} from '@chakra-ui/react';
import GFLButton from '../atoms/GFLButton';
import { FaFacebookF, FaGooglePlusG } from 'react-icons/fa';
import { BiGlobe, BiSupport } from 'react-icons/bi';

import { Link } from 'gatsby';
import { GrContact } from 'react-icons/gr';
import { MdContacts, MdSupport } from 'react-icons/md';
import { RiContactsBookLine, RiContactsFill } from 'react-icons/ri';
import { AiFillContacts } from 'react-icons/ai';
import { HiOutlineSupport, HiSupport } from 'react-icons/hi';

interface IProps {
  onServicesLinkClicked?: (service: string) => void;
}

const Footer = ({ onServicesLinkClicked }: IProps) => {
  return (
    <>
      {/* <Center backgroundColor="#ff0000" py="2.8em" w="100%">
        <Container maxW="container.xl" w="100%">
          <SimpleGrid
            columns={[1, 1, 2]}
            backgroundColor="#ff0000"
            justifyContent={'space-between'}
            color="white"
            w="100%"
            spacing="1.6em"
          >
            <VStack alignItems="flex-start" spacing="1.5em">
              <Heading fontSize="1.5rem" color="white">
                YOUR SOLUTION TO TOTAL TRANSPORTATION
              </Heading>
              <HStack spacing="1em">
                <FaFacebookF
                  style={{
                    width: '22px',
                    height: '22px',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    window.open('https://www.facebook.com/ISCGFL');
                  }}
                />
                <FaGooglePlusG
                  style={{
                    width: '30px',
                    height: '30px',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    window.location.href = 'mailto:info@isc-gfl.com.my';
                  }}
                />
              </HStack>
              <Text color="white">
                © GFL Group of Companies. All rights reserved.
              </Text>
            </VStack>
            <VStack
              alignItems={'flex-start'}
              spacing="1.5em"
              w="100%"
              color="white"
            >
              <Text>KEEP UP TO DATE:</Text>
              <HStack w="100%">
                <Input
                  placeholder="Email Address"
                  color="white"
                  borderColor="white"
                  className="email-address-input"
                />
                <GFLButton
                  minW="125px"
                  border={'1px solid white'}
                  color={'red'}
                  backgroundColor={'white'}
                >
                  Sign me up!
                </GFLButton>
              </HStack>

              <HStack flexWrap={'wrap'}>
                <Link to="#">Privacy </Link>
                <Text>/</Text>
                <Link to="#">Terms & Conditions</Link>
                <Text>/</Text>
                <Link to="#">Site map</Link>
                <Text>/</Text>
                <Link to="#">Contact Us </Link>
              </HStack>
            </VStack>
          </SimpleGrid>
        </Container>
      </Center> */}
      <Center py="4em" backgroundColor="#213A71" color="white">
        <Container maxW="container.xl" textAlign={'center'}>
          <Heading
            fontSize={['1.4rem', '1.8rem', '2.4rem']}
            color="white"
            mb="40px"
          >
            YOUR SOLUTION TO TOTAL CARGO TRANSPORTATION
          </Heading>
          <SimpleGrid
            columns={[1, 1, 2]}
            alignItems="center"
            py="2em"
            gap={['2em', '2em', 0]}
          >
            <VStack alignItems="flex-start">
              <Image
                src={'/logo/GFL_GROUP_DARK.svg'}
                alt="GFL Logo"
                width={'275px'}
                height={'auto'}
                onClick={() => {
                  if (typeof window !== 'undefined') {
                    window.location.href = '/';
                  }
                }}
                cursor="pointer"
                mb="15px"
              />
              <VStack alignItems="flex-start" spacing="1.5em">
                <HStack spacing="1em">
                  <FaFacebookF
                    style={{
                      width: '22px',
                      height: '22px',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      window.open('https://www.facebook.com/ISCGFL');
                    }}
                  />
                  <FaGooglePlusG
                    style={{
                      width: '30px',
                      height: '30px',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      window.location.href = 'mailto:info@isc-gfl.com.my';
                    }}
                  />
                </HStack>
                <Text color="white" textAlign={'start'}>
                  © GFL Group of Companies. All rights reserved.
                </Text>
              </VStack>
            </VStack>
            <Flex flexDir={['column', 'column', 'row']} gap="2em">
              <Flex
                flexDir={['column', 'column', 'row']}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                gap="20px"
              >
                <BiSupport
                  style={{
                    width: '60px',
                    height: '60px',
                    color: '#FFFFFF',
                  }}
                />

                <VStack alignItems={'flex-start'}>
                  <Text fontWeight="600" fontSize="1.2rem">
                    Contact Us
                  </Text>
                  <Link
                    to="/"
                    style={{
                      textAlign: 'start',
                    }}
                  >
                    + 603-3162 5454
                  </Link>
                </VStack>
              </Flex>
              <Flex
                flexDir={['column', 'column', 'row']}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                gap="20px"
                w="55%"
              >
                <Image src={'/custom-icons/earth-white.svg'} />
                <VStack alignItems={'flex-start'}>
                  <Text fontWeight="600" fontSize="1.2rem">
                    Location
                  </Text>
                  <Text textAlign={'start'}>
                    Lot 1640, Lebuh Raja Lumu, PKNS Industrial Park, 42000 Port
                    Klang, Selangor
                  </Text>
                </VStack>
              </Flex>
            </Flex>
          </SimpleGrid>

          <Divider />
          <SimpleGrid columns={[1, 1, 3]} py="2.5em" gap={['2em', '2em', 0]}>
            <VStack alignItems={'flex-start'}>
              <Heading fontSize="1.5rem" color="white">
                Services
              </Heading>
              <Link
                onClick={() => {
                  onServicesLinkClicked && onServicesLinkClicked('Sea Freight');
                }}
                to="/services/#sea-freight"
              >
                Sea Freight
              </Link>
              <Link
                onClick={() => {
                  onServicesLinkClicked && onServicesLinkClicked('Air Freight');
                }}
                to="/services/#air-freight"
              >
                Air Freight
              </Link>
              <Link
                onClick={() => {
                  onServicesLinkClicked &&
                    onServicesLinkClicked('Customs Clearance');
                }}
                to="/services/#customs-clearance"
              >
                Customs Clearance
              </Link>
              <Link
                onClick={() => {
                  onServicesLinkClicked &&
                    onServicesLinkClicked('Project Cargo Handling');
                }}
                to="/services/#project-cargo-handling"
              >
                Project Cargo Handling
              </Link>
              <Link
                onClick={() => {
                  onServicesLinkClicked &&
                    onServicesLinkClicked('Transloading');
                }}
                to="/services/#transloading"
              >
                Transloading
              </Link>
              <Link
                onClick={() => {
                  onServicesLinkClicked &&
                    onServicesLinkClicked('Land Transportation');
                }}
                to="/services/#land-transportation"
              >
                Land Transportation
              </Link>
              <Link
                onClick={() => {
                  onServicesLinkClicked &&
                    onServicesLinkClicked('Marine Insurance');
                }}
                to="/services/#marine-insurance"
              >
                Marine Insurance
              </Link>
              <Link
                onClick={() => {
                  onServicesLinkClicked &&
                    onServicesLinkClicked('Warehousing And Distribution');
                }}
                to="/services/#warehousing-and-distribution"
              >
                Warehousing And Distribution
              </Link>
              <Link
                onClick={() => {
                  onServicesLinkClicked && onServicesLinkClicked('Value-Added');
                }}
                to="/services/#value-added"
              >
                Value-Added
              </Link>
            </VStack>
            <VStack alignItems={'flex-start'}>
              <Heading fontSize="1.5rem" color="white">
                Useful Links
              </Heading>
              <Link to="/incoterms">Incoterms 2020 Table</Link>
              <Link to="/seafreight">Container Specifications</Link>
              <Link to="/search">Seaport and Airport Codes</Link>
              <Link to="/search">Glossary of Shipping Term</Link>
              <Link to="https://fmff.net/">FMFF</Link>
              <Link to="https://fmff.net/wp-content/uploads/2021/05/FMFF-STC-Final-24042021-3COL-1.pdf">
                FMFF STC
              </Link>
            </VStack>

            <VStack alignItems={'flex-start'} fontWeight={'800'}>
              <Link to="/">Home</Link>
              <Link to="/company">About</Link>
              <Link to="/services">Services</Link>
              <Link to="/resources">Resources</Link>
              <Link to="/contact">Contact Us</Link>
              <GFLButton
                color={'white'}
                marginTop={['1.2em', '1.2em', 0]}
                onClick={() => {
                  window.location.href = '/contact';
                }}
              >
                Get Enquiry
              </GFLButton>
            </VStack>
          </SimpleGrid>
        </Container>
      </Center>
    </>
  );
};

export default Footer;
