import React from 'react';
import { HStack } from '@chakra-ui/react';
const TextUnderline = ({ color = '#213A71', ...props }) => {
  return (
    <HStack
      style={{
        marginTop: '0.2em',
        marginBottom: '0.6em',
      }}
      backgroundColor={color}
      w="12%"
      h="5px"
      {...props}
    ></HStack>
  );
};

export default TextUnderline;
