import {
  Box,
  Center,
  HStack,
  Container,
  Image,
  Link,
  Button,
  VStack,
  Input,
  CloseButton,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';

import { GFLButton } from '../atoms';
import { BsFillPhoneLandscapeFill, BsSearch } from 'react-icons/bs';
import '../../css/index.css';
import axios from 'axios';
import { RiTempHotLine } from 'react-icons/ri';
import { IoCall } from 'react-icons/io5';
import { CloseIcon, EmailIcon, HamburgerIcon } from '@chakra-ui/icons';
import { useWindowSize } from '../../hooks';

const headerLinks = [
  {
    name: 'HOME',
    href: '/',
  },
  {
    name: 'ABOUT',
    href: '/company/',
    sub: [
      {
        name: 'Who Are We',
        href: '/company/#who-are-we',
      },
      {
        name: 'About Us',
        href: '/company/#about',
      },
      {
        name: 'Vision and Mission',
        href: '/company/#mission-and-vision',
      },
      {
        name: 'Core Values',
        href: '/company/#core-values',
      },
      {
        name: 'Message from Group Managing Director',
        href: '/company/#dato-message',
      },
    ],
  },
  {
    name: 'SERVICES',
    href: '/services/',
    sub: [
      {
        name: 'Sea Freight',
        href: '/services/#sea-freight',
      },
      {
        name: 'Air Freight',
        href: '/services/#air-freight',
      },
      {
        name: 'Customs Clearance',
        href: '/services/#customs-clearance',
      },
      {
        name: 'Project Cargo Handling',
        href: '/services/#project-cargo-handling',
      },
      {
        name: 'Transloading',
        href: '/services/#transloading',
      },
      {
        name: 'Land Transportation',
        href: '/services/#land-transportation',
      },
      {
        name: 'Marine Insurance',
        href: '/services/#marine-insurance',
      },
      {
        name: 'Warehousing And Distribution',
        href: '/services/#warehousing-and-distribution',
      },
      {
        name: 'Value-Added',
        href: '/services/#value-added',
      },
    ],
  },
  {
    name: 'RESOURCES',
    href: '/resources/',
  },
  {
    name: 'CONTACT US',
    href: '/contact/',
  },
];

const header2Links = [
  {
    name: 'HOME',
    href: '/',
  },
  {
    name: 'ABOUT',
    href: '/company/',
  },
  {
    name: 'SERVICES',
    href: '/services/',
  },
  {
    name: 'RESOURCES',
    href: '/resources/',
  },
  {
    name: 'Contact Us',
    href: '/contact/',
  },
];

interface IHeader {
  onServiceTagChange?: any;
}

const Header = ({ onServiceTagChange }: IHeader) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isHeaderTwoVisible, setIsHeaderTwoVisible] = React.useState(false);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = React.useState(false);
  const header2 = React.useRef(null);
  const aboutHover = React.useRef<HTMLDivElement>(null);
  const serviceHover = React.useRef<HTMLDivElement>(null);
  const { width, height } = useWindowSize();

  const handleScroll = (e: any) => {
    const window = e.currentTarget;

    if (window.innerWidth > 1000) {
      if (window.scrollY > 20) {
        setIsHeaderTwoVisible(true);
      } else {
        setIsHeaderTwoVisible(false);
      }
    } else {
    }
  };

  const aboutToggle = (status: Boolean) => {
    if (aboutHover.current && status) {
      aboutHover.current.style.visibility = 'visible';
      aboutHover.current.style.opacity = '1';
    } else if (aboutHover.current && !status) {
      aboutHover.current.style.visibility = 'hidden';
      aboutHover.current.style.opacity = '0';
    } else {
      return;
    }
  };

  const serviceToggle = (status: Boolean) => {
    if (serviceHover.current && status) {
      serviceHover.current.style.visibility = 'visible';
      serviceHover.current.style.opacity = '1';
    } else if (serviceHover.current && !status) {
      serviceHover.current.style.visibility = 'hidden';
      serviceHover.current.style.opacity = '0';
    } else {
      return;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', (e) => handleScroll(e));

    return () => {
      window.removeEventListener('scroll', (e) => handleScroll(e));
    };
  }, []);

  const Identifier = ({ link }: { link: { name: string; href: string } }) => {
    const isBrowser = typeof window !== 'undefined';

    if (isBrowser) {
      return (
        <Box
          d={
            window.location.pathname === link.href.toLowerCase()
              ? 'block'
              : 'none'
          }
          position={'absolute'}
          bottom={'-12px'}
          left={0}
          w="100%"
          h="3px"
          bgColor="#213A71"
          mt="10px"
          style={{
            content: ' ',
          }}
        ></Box>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Center
        visibility={isOpen ? 'visible' : 'hidden'}
        opacity={isOpen ? 1 : 0}
        position={'fixed'}
        top="0"
        left="0"
        w="100vw"
        h="100vh"
        background="white"
        cursor={'pointer'}
        zIndex={1000}
        transition="all 0.3s ease-in-out"
        id="header-one"
      >
        <CloseButton
          position={'absolute'}
          top="70px"
          right="70px"
          size={'xl'}
          color={'black'}
          onClick={() => {
            setIsOpen(false);
          }}
        />

        <Container maxW="container.xl" zIndex={101}>
          <Input
            type="search"
            variant="unstyled"
            name="search"
            placeholder="Search..."
            color={'blue !important'}
            fontSize="45px"
            size={'lg'}
            borderBottom={'1px solid blue'}
            borderRadius={'0px'}
          />
        </Container>
      </Center>
      <VStack
        position={'sticky'}
        top={0}
        zIndex={150}
        spacing={0}
        boxShadow="md"
        id="header-two"
      >
        <header
          style={{
            width: '100%',
            backgroundColor: '#213A71',
          }}
        >
          <Center id="header" w="100%" py="15px" h="100%">
            <Container maxW="container.xl">
              <VStack w="100%">
                <HStack justifyContent={'space-between'} w="100%">
                  <Image
                    src={'/logo/GFL_GROUP_DARK.svg'}
                    alt="GFL Logo"
                    width={['150px', '200px', '275px']}
                    height={'auto'}
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.location.href = '/';
                      }
                    }}
                    cursor="pointer"
                  />

                  <Box d={['block', 'block', 'block', 'none']}>
                    {isMobileMenuVisible ? (
                      <CloseIcon
                        onClick={() => {
                          setIsMobileMenuVisible(!isMobileMenuVisible);
                        }}
                        style={{
                          color: 'white',
                        }}
                      />
                    ) : (
                      <HamburgerIcon
                        onClick={() => {
                          setIsMobileMenuVisible(!isMobileMenuVisible);
                        }}
                        style={{
                          color: 'white',
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    )}
                  </Box>

                  <HStack
                    spacing={'25px'}
                    justifyContent="flex-end"
                    d={['none', 'none', 'none', 'flex']}
                  >
                    {headerLinks.map((link, index) => (
                      <Box position="relative">
                        <Link
                          key={index}
                          href={link.href}
                          fontWeight="bold"
                          style={{
                            color: 'white',
                          }}
                          onMouseOver={() => {
                            link.name === 'ABOUT'
                              ? aboutToggle(true)
                              : link.name === 'SERVICES' && serviceToggle(true);
                          }}
                          onMouseLeave={() => {
                            link.name === 'ABOUT'
                              ? aboutToggle(false)
                              : link.name === 'SERVICES' &&
                                serviceToggle(false);
                          }}
                        >
                          {link.name}
                        </Link>

                        {(link.name === 'ABOUT' ||
                          link.name === 'SERVICES') && (
                          <VStack
                            position={'absolute'}
                            top="140%"
                            left={'-50%'}
                            alignItems="flex-start"
                            w="max-content"
                            background={'white'}
                            borderRadius="8px"
                            padding={'20px 30px '}
                            spacing={'15px'}
                            visibility={'hidden'}
                            opacity={0}
                            transition="all 0.3s ease-in-out"
                            zIndex={100}
                            boxShadow="md"
                            ref={
                              link.name === 'ABOUT' ? aboutHover : serviceHover
                            }
                            onMouseOver={() => {
                              link.name === 'ABOUT'
                                ? aboutToggle(true)
                                : link.name === 'SERVICES' &&
                                  serviceToggle(true);
                            }}
                            onMouseLeave={() => {
                              link.name === 'ABOUT'
                                ? aboutToggle(false)
                                : link.name === 'SERVICES' &&
                                  serviceToggle(false);
                            }}
                          >
                            {link.sub!.map((subLink, subIndex) => (
                              <Link
                                href={subLink.href}
                                cursor={'pointer'}
                                onClick={() => {
                                  if (onServiceTagChange) {
                                    onServiceTagChange(subLink.name);
                                  }
                                }}
                              >
                                <Text color={'#2b2b2b'}>{subLink.name}</Text>
                              </Link>
                            ))}
                          </VStack>
                        )}
                      </Box>
                    ))}

                    <GFLButton
                      onClick={() => {
                        window.location.href = '/contact';
                      }}
                      color="white"
                      fontWeight="bold"
                      px="40px"
                      py="20px"
                      minH="50px"
                    >
                      GET ENQUIRY
                    </GFLButton>
                  </HStack>
                </HStack>
              </VStack>
            </Container>
          </Center>
        </header>

        {width > 768 && (
          <Center
            id="header2"
            w="100%"
            py={isHeaderTwoVisible ? '15px' : '0px'}
            h={isHeaderTwoVisible ? '100%' : '0px'}
            visibility={isHeaderTwoVisible ? 'visible' : 'hidden'}
            backgroundColor={'white'}
            ref={header2}
            transform={isHeaderTwoVisible ? 'scaleY(1)' : 'scaleY(0)'}
            transformOrigin={'top'}
            transition="all 0.3s ease-in-out"
          >
            <Container maxW="container.xl">
              <HStack
                w="100%"
                justifyContent={[
                  'space-between',
                  'space-between',
                  'flex-start',
                ]}
                spacing={['5', '10', '20']}
              >
                <HStack>
                  <Text
                    color={'#2b2b2b'}
                    d="flex"
                    flexDir="row"
                    alignItems={'center'}
                    fontSize={['12px', '14px', '16px']}
                  >
                    <IoCall
                      style={{
                        marginRight: '4px',
                      }}
                    />
                    + 603-3162 5454
                  </Text>
                </HStack>
                <HStack>
                  <Text color={'#2b2b2b'} fontSize={['12px', '14px', '16px']}>
                    <EmailIcon
                      style={{
                        marginRight: '4px',
                      }}
                    />
                    info@isc-gfl.com.my
                  </Text>
                </HStack>
              </HStack>
            </Container>
          </Center>
        )}

        <Center
          id="mobile-menu"
          w="80vw"
          py="0.5rem"
          px="0.5rem"
          margin={'0 auto'}
          backgroundColor={'#122346'}
          transformOrigin={'top'}
          transition="all 0.3s ease-in-out"
          position={'fixed'}
          top={'80px'}
          left={'50%'}
          transform={
            isMobileMenuVisible ? 'translateX(-50%)' : 'translateX(-50%)'
          }
          visibility={isMobileMenuVisible ? 'visible' : 'hidden'}
          opacity={isMobileMenuVisible ? '1' : '0'}
          zIndex={100}
          textAlign={'center'}
        >
          <Container maxW="container.xl">
            <VStack
              alignItems={'flex-start'}
              spacing={'25px'}
              justifyContent="flex-end"
              d={'flex'}
              paddingY="10px"
            >
              {headerLinks.map((link, index) => (
                <Box position="relative">
                  <Link
                    key={index}
                    href={link.href}
                    fontWeight="bold"
                    style={{
                      color: 'white',
                    }}
                  >
                    <Text fontSize={'12px'}>{link.name}</Text>
                  </Link>
                </Box>
              ))}
            </VStack>
          </Container>
        </Center>
      </VStack>
    </>
  );
};

export default Header;
