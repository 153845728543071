import { Button } from '@chakra-ui/react';
import React from 'react';

interface Props {
  children: any;
  [key: string]: any;
}

const GFLButton = ({ children, ...props }: Props) => {
  return (
    <Button
      rounded="none"
      bgColor={'#f93615'}
      border="1px solid #f93615 !important"
      color="black"
      _hover={{
        bgColor: '#213A71',
        color: 'white',
        border: '1px solid transparent',
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default GFLButton;
