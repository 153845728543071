import React, { useEffect, useRef } from 'react';
import { Box, Center, Image, VStack } from '@chakra-ui/react';

const Loader = () => {
  let screenRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      screenRef.current.style.opacity = 0;
      // @ts-ignore
      screenRef.current.style.height = '0%';
    }, 1200);
  });

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100vh"
      overflow="hidden"
      zIndex={9999}
      pointerEvents="none"
    >
      <Center
        position="relative"
        paddingX={[10, 10, 0]}
        paddingY={0}
        backgroundColor="white"
        opacity={1}
        transition={'all 0.3s ease-in-out'}
        width="100%"
        height="100%"
        ref={screenRef}
      >
        <VStack spacing={'20'}>
          <Image
            src={'/loading.gif'}
            alt="gif"
            height={['30vh', '35vh', '35vh']}
            w="auto"
            borderRadius={'50%'}
            className={'rotating'}
          />
        </VStack>
      </Center>
    </Box>
  );
};

export default Loader;
